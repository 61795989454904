import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  currentUser :any;
  accessToken = "";
  constructor(public http: HttpClient) {

    try{
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.accessToken = this.currentUser.accessToken;
    }catch(error){

    }
   }



  getAllBranches(): any {
    const token =  this.accessToken;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`

    });
    return this.http.get<string>(`${environment.apiUrl}/common/branch`,{ headers });  
  }

  getAllusers(): any {
    const token =  this.accessToken;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`

    });
    return this.http.get<string>(`${environment.apiUrl}/masters/users`,{ headers });  
  }

  getAllcustomers(): any {
    const token =  this.accessToken;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`

    });
    return this.http.get<string>(`${environment.apiUrl}/masters/customer`,{ headers });  
  }

  getloannumber(): any {
    const token =  this.accessToken;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`

    });
    return this.http.get<string>(`${environment.apiUrl}/masters/loannumber`,{ headers });  
  }

  

  getAllinvestors(): any {
    const token =  this.accessToken;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`

    });
    return this.http.get<string>(`${environment.apiUrl}/masters/invester`,{ headers });  
  }



  getAllDistric(): any {
    const token =  this.accessToken;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`

    });
    return this.http.get<string>(`${environment.apiUrl}/masters/district`,{ headers });  
  }

  getAllVehiceltype():any{
    return this.http.get<string>(environment.apiUrl+'/masters/vehicletype');
  }

  set(keys, value){
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
    {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
  }

  //The get method is use for decrypt the value.
  get(keys, value){
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var decrypted = CryptoJS.AES.decrypt(value, key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }


  getSideNav(userId : any): any {
    const token =  this.accessToken;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`

    });

    var data = {
      UserId : userId
    }
    return this.http.post<any>(`${environment.apiUrl}/sidenav/getpermissions`, data, { headers });  
  }

  postErroLog(errorCode : any, type : any, message : any, userId : any): any {
    const token =  this.accessToken;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`

    });

    var data = {
        errorCode:errorCode,
        trnsType:type,
        errorDisc:message,
        userId:userId
    }
 
    return this.http.post<any>(`${environment.apiUrl}/masters/log`, data, { headers });  
  }



  reportarrears(dataset : any): any {
    const token =  this.accessToken;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`

    });

     
 
    return this.http.post<any>(`${environment.apiUrl}/reports/arrearsreport`, dataset, { headers });  
  }


  collectionreport(dataset : any): any {
    const token =  this.accessToken;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`

    });

     
 
    return this.http.post<any>(`${environment.apiUrl}/reports/collectionreport`, dataset, { headers });  
  }

  lendingreport(dataset : any): any {
    const token =  this.accessToken;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`

    });

     
 
    return this.http.post<any>(`${environment.apiUrl}/reports/lendingreport`, dataset, { headers });  
  }

  investmentpayment(dataset : any): any {
    const token =  this.accessToken;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`

    });

     
 
    return this.http.post<any>(`${environment.apiUrl}/reports/investmentpayment`, dataset, { headers });  
  }

  investmentsummery(dataset : any): any {
    const token =  this.accessToken;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`

    });

     
 
    return this.http.post<any>(`${environment.apiUrl}/reports/investmentsummery`, dataset, { headers });  
  }


  loadinvesterpayment(dataset : any): any {
    const token =  this.accessToken;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`

    }); 
    return this.http.post<any>(`${environment.apiUrl}/masters/investerpayment`, dataset, { headers });  
  }
  saveinvesterpayment(dataset : any): any {
    const token =  this.accessToken;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`

    }); 
    return this.http.post<any>(`${environment.apiUrl}/masters/saveinvesterpayment`, dataset, { headers });  
  }



}
