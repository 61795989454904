import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Page404Component } from './authentication/page404/page404.component';
import { AuthGuard } from './core/guard/auth.guard';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';


const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./A-domex/components/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      //pickup inquries main routing
     
      {
        path: 'master-data',
        loadChildren: () =>
          import('./A-domex/components/MasterData/masterdata.module').then(
            (m) => m.MasterdataModule
          )
      } ,

      {
        path: 'loan-module',
        loadChildren: () =>
          import('./A-domex/components/LoanModule/morgageloan.module').then(
            (m) => m.MorgageloanModule
          )
      } ,
      {
        path: 'report-module',
        loadChildren: () =>
          import('./A-domex/components/reports/reports/reports.module').then(
            (m) => m.ReportsModule
          )
      } 



    ]
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  },

  {
    path: '**',
    component: Page404Component
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
