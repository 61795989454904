import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [];


export const ROUTES2: RouteInfo[] = [
  {
    path: 'dashboard',
    title: 'Dashboard',
    icon: 'trello',
    class: '',
    groupTitle: false,
    submenu: []
  },

  {
    path: '',
    title: 'Pickup Inquries',
    icon: 'monitor',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'pickup-inquries/pickup-list',
        title: 'Pickup List',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'pickup-inquries/create-pickup',
        title: 'Create Pickup',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },

  {
    path: '',
    title: 'Pickup Branch',
    icon: 'monitor',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'pickup-branch/check-in',
        title: 'Check In',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'pickup-branch/return-list',
        title: 'Return List',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'pickup-branch/sorting',
        title: 'Sorting',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'pickup-branch/sorting-list',
        title: 'Sorted List',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'pickup-branch/check-out',
        title: 'Check Out',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },

  {
    path: '',
    title: 'Warehouse',
    icon: 'monitor',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'intransit-location/checkin-list',
        title: 'Check In List',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'intransit-location/checkout-list',
        title: 'Check Out List',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Delivery Branch',
    icon: 'monitor',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'delivery-branch/check-in',
        title: 'Check In',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'delivery-branch/miss-route',
        title: 'MissRoute',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'delivery-branch/check-out',
        title: 'Check Out',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'delivery-branch/delivery-list',
        title: 'Delivery List',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },

  {
    path: '',
    title: 'Complaints',
    icon: 'monitor',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'complaints/complaints-list',
        title: 'Complaints List',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'complaints/create-complaints',
        title: 'Create Complaints',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },

  {
    path: '',
    title: 'Recovery',
    icon: 'monitor',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'recovery/courier-wise',
        title: 'Courier Wise',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'recovery/collection-report',
        title: 'Collection Report',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'recovery/petty-cash-list',
        title: 'Petty cash',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'recovery/Oustandings',
        title: 'Outstandings',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
  {
    path: 'Dashboard',
    title: 'Couriers',
    icon: 'trello',
    class: '',
    groupTitle: false,
    submenu: []
  },

  {
    path: 'Dashboard',
    title: 'Reports',
    icon: 'trello',
    class: '',
    groupTitle: false,
    submenu: []
  },

  {
    path: 'Dashboard',
    title: 'Master Data',
    icon: 'trello',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'master-data/manage-role',
        title: 'Role',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'Dashboard',
        title: 'Pricing Details',
        icon: 'monitor',
        class: 'ml-menu',
        groupTitle: false,
        
        submenu: [
          {
            path: 'master-data/pricing-discount',
            title: 'Discounts',
            icon: '',
            class: '',
            groupTitle: false,
            submenu: [],
          },
          {
            path: 'master-data/pricing-itemlist',
            title: 'Item List',
            icon: '',
            class: '',
            groupTitle: false,
            submenu: []
          },
          {
            path: 'master-data/pricing-standards',
            title: 'Standards',
            icon: '',
            class: '',
            groupTitle: false,
            submenu: []
          }
        ]
      },
      
      {
        path: 'master-data/role-permission',
        title: 'Role Permission',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'master-data/permission',
        title: 'Permission',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'master-data/branch',
        title: 'Branch',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'master-data/permission-module',
        title: 'Module',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },

      {
        path: 'master-data/courier-vice-vehicle',
        title: 'Courier Wise Vehicle',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },

      {
        path: 'master-data/master-petty-cash',
        title: 'PettyCash Expenses',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }

    ]
  },

  {
    path: '',
    title: 'Registration',
    icon: 'monitor',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'master-data/customers',
        title: 'Customers',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      // {
      //   path: 'master-data/employee',
      //   title: 'Employees',
      //   icon: '',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   submenu: []
      // },
      {
        path: 'master-data/vehicle',
        title: 'Vehicles',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'master-data/manage-user',
        title: 'Users',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'master-data/manage-courier',
        title: 'Couriers',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'registration/check-out',
        title: 'Check Out',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'registration/ItemMaster',
        title: 'Item Master',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },

  {
    path: '',
    title: 'ListView',
    icon: 'monitor',
    class: 'menu-toggle',
    groupTitle: false,
    submenu: [
      {
        path: 'listview/cancelitem-list',
        title: 'Cancel Item List',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'listview/holditem-list',
        title: 'Hold Item List',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'listview/missingitem-list',
        title: 'Missing Item List',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'listview/rescheduleitem-list',
        title: 'Resch. Item List',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: 'listview/returnitem-list',
        title: 'Return Item List',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      }
    ]
  },
];
