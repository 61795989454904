export class User {
  id: number;
  userName: string;
  password: string;
  firstName: string;
  lastName: string;
  accessToken: string;
  success: boolean;
  message:string;
  branch:number;
  BranchName:string;
  roleid:number;
}
