import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  loginreturn=new User();
  login(username: string, password: string) {
    return this.http

      .post<any>(`${environment.apiUrl}/Auth/Login`, {

        username,
        password

      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
         var logReturn=user.sync_data.user_info;
          if (user.response=="success") {

            this.loginreturn.accessToken=logReturn.Token;
            this.loginreturn.userName=logReturn.email;
            this.loginreturn.firstName=logReturn.full_name;
            this.loginreturn.lastName=logReturn.LastName;
            this.loginreturn.id=logReturn.user_id;
            this.loginreturn.message=user.message;
            this.loginreturn.branch=logReturn.branch_id;
            this.loginreturn.BranchName=logReturn.BranchName; 
            this.loginreturn.roleid=logReturn.RoleId;

            localStorage.setItem('currentUser', JSON.stringify(this.loginreturn));
            this.currentUserSubject.next(this.loginreturn);

          }
          else{
            this.loginreturn.message=user.message;
            localStorage.setItem('currentUser', JSON.stringify(this.loginreturn));
            this.currentUserSubject.next(this.loginreturn);
          }
          return this.loginreturn;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return of({ success: false });
  }
}
