import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'datetimeValidation'
})
export class DatetimePipe implements PipeTransform {

  transform(value: any, format: string = 'YYYY-MM-DDTHH:mm:ss.SSSZ'): boolean {
    if (value) {
      const isValid = moment(value, format, true).isValid();
      return isValid;
    } else {
      return true;
    }
  }

}
